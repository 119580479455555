.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100vh;
  width: 100vw;

  position: relative;

  .logo {
    width: 80%;
    max-width: 300px;
    margin-bottom: 50px;
    fill: #fff;

    @media (min-width: 768px) {
      width: initial;
    }
  }

  .mobileMenu {
    display: flex;
    flex-direction: column;
    font-size: 32px;

    hr {
      margin: 15px 0;
    }
    button {
      margin: auto;
      display: block;
    }
   
    .link {
      align-self: flex-start;
      margin: 5px auto;
      &.active {
        text-decoration: underline;
        cursor: default;
      }
    }
  }
}

.close {
  position: absolute;
  top: 25px;
  left: 25px;

  cursor: pointer;
}
