.textArea {
    opacity: 0;
    width: 0px;
    height: 0px;
    padding: 0;
    margin: 0;
    
}

.copyToClipboard {
  overflow: hidden;
  cursor: pointer;

  &:disabled,
  &[disabled] {
    cursor: no-drop;
  }
}

.copied {
  font-size: 14px;
  line-height: 14px;
  padding: 20px;
  position: absolute;
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
  color: green;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;

  &.hidden {
    opacity: 0;
  }
}