@import 'variables.scss';

.token {
  
}

.codeWrap {
  position: relative;
  display: flex;
}

.code {
  // transition: filter 0.2s ease-in;
}

.actionBar {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  top: 0;
  right: 0;
  padding: 10px;
  background: rgba(255, 255, 255, 0.7);
  transition: opacity 0.2s ease-in-out;
  border-bottom-left-radius: 5px;
}

.codeWrap:hover {

  .actionBar {
    opacity: 1;
    display: inline-block;
    pointer-events: initial;
  }
}