.jwtView {
  
}

.tokenField {
  width: 100%;
  min-height: 400px;
}

.code {
  width: 100%;
  min-height: 400px;
}

.json {
  cursor: default;
}

.input {
  border: 1px solid transparent;
  word-wrap: break-word;

  &:focus {
    outline: none;
    border-color: #3498DB;
  }
  &.invalid {
    border-color: red;
  }
  &.valid:focus-visible {
    border-color: green;
  }

}



