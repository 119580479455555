.nestedData {

  .key {
    background: rgba(0, 0, 0, 0.1);
    font-weight: bold;
    text-align: right;
    padding: 5px 10px 5px 20px;
  }

  .value {
    padding: 5px 20px 5px 10px;
  }

  td {
    padding: 0;
  }
}

.arrayItem {
  padding-bottom: 5px;

  &:last-child {
    padding-bottom: 0;
  }
}