.externalLink {
  display: inline-flex;
  align-items: center;

  // fixme: not working
  // &.text::before {
  //   content: "";
  //   display: inline-block;
  //   background: url("./external.svg") no-repeat;
  //   width: 15px;
  //   height: 15px;
  //   margin-right: 3px;
  // }

}
