@import 'variables';

.container {
    position: relative;
}
.avatar {
    background-color: transparent;
    position: relative;
    z-index: 2;
}

.fadeIn {
    opacity: 0;
    animation: fadeIn 300ms ease-in forwards;
}

.loader {
    top: 0;
    position: absolute;
    background: linear-gradient(-45deg, $volt, $nikePlusCyan, $teamOrange, $nikeFuel);
    background-size: 400% 400%;
    animation: gradient 30s ease infinite;
    z-index: 2;
    z-index: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}