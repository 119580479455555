@import 'variables.scss';

.contextualHeader {
  background: $nikeOrange;
  h6 {
    width: 100%;
  }
}

.aliases {
  color: rgba(0, 0, 0, 0.8);
  display: inline;
  padding-left: 15px;
  font-style: italic;
  font-size: 14px;
}
