@import 'variables';

h1 {
    margin-bottom: 20px;
}

.header {
    align-items: baseline;
}

.header,
.linkRow,
.chartRow {
    margin: 0 auto;
}

.chartRow {
    h5 {
        text-align: center;
    }
}

.chartHeight {
    height: 300px;
}

.linkRow {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
}

.link {
    width: 30%;
    text-align: center;
    transition: all $hoverTiming ease-in-out;
    padding: 10px;
    border-radius: 5px;
    &:hover {
        background-color: rgba(200,200,200,0.5);
    }
}

.tooltipWrapper {
    background-color: $white;
    color: $black;
    border: 1px solid $black;
    border-radius: 5px;
    padding: 0 15px;
}

.stageRow {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 15px;
    justify-content: start;

    .phaseLabel {
        display: flex;
        align-items: flex-start;
        padding-right: 15px;
    }
}

.insightsBanner {
    margin: 10px auto;
}

.stat {
    color: $workshopGreen;
}

.logoWrap {
  max-width: 350px;
  max-height: 350px;
  margin: 60px auto 30px auto;
  display: block;
  text-align: center;
  position: relative;
}

.logo {
  max-width: 350px;
  max-height: 350px;
  margin: 60px auto 30px auto;
  display: block;
  text-align: center;
}

.inactive {
  position: absolute;
  top: -30px;
  right: -30px;
  width: 75px;
}

.row {
  display: flex;
  height: 150px;
}

.description {
  font-size: 22px;
  margin-top: 50px;
}

.url {
  font-size: 24px;
  padding-top: 20px;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  margin-right: 10px;
  text-decoration: none;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  min-width: 150px;

  .top {
    height: 60%;
    display: flex;
    align-items: center;
  }
  .bottom {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 150px;
    max-height: 100%;
  }

  p {
    margin: 0;
  }
}

.app {
  .icon {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .inactive {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 50px;
  }
}

/* only show hover ui when the target is clickable */
a.card:hover {
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

@media (min-width: $sm) {
    .chartRow {
        height: 300px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
