.label {
  align-self: center;
}

.playground {
  height: calc(100vh - 120px);
  border-top: 1px solid #d0d0d0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  
  a {
    color: initial;
    &:hover {
      color: initial;
      text-decoration: underline;
    }
  }
  svg {
    fill: black !important;
    stroke: black;
  }

  label {
    color: black !important;
  }
}

.currentUrl {
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  font-size: 14px;
  padding: 1px 8px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: text;
}

.dropdown {
  outline: none;
}

.fullscreen {
  color: #555 !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  cursor: pointer;
}

.resMeta {
  display: flex;
  font-weight: bold;
  font-size: 16px;
  vertical-align: middle;

  span {
    margin-right: 5px;
    padding: 5px 0 0 5px;
  }
}

.seperator {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0 10px;
}

:global(.graphiql-container) {

  :global(.field-short-description) p {
    margin-top: 5px;
  }

  :global(.toolbar-button),
  :global(select) {
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
    &[disabled] {
      cursor: no-drop;
      opacity: 0.6;
      box-shadow: none;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  :global(.secondary-editor-title) {
    display: flex;
  }
  
  :global(.variable-editor-title-text) {
    font-weight: initial;
    cursor: pointer;

    &:global(.active) {
      font-weight: bold;
      cursor: default;
    }
  }

  // fixme
  :global(.toolbar-button) {
    max-width: initial;
  }

  :global(.material-icons) {
    vertical-align: middle;
  }

  :global(.variable-editor) {
    height: 100%;
  }

  :global(.field-short-description),
  :global(.doc-type-description) {
    a {
      text-decoration: underline;

      &:hover {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

}
