@import 'variables';

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    color: inherit;
    margin-top: 20px;
}

.card {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    transition: 0.25s;
    border-radius: 5px;
    font-size: 30px;
    min-width: 400px;
    text-align: center;
    padding: 30px;

    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 300px;
    }
    // img {
      
    // }

    h3 {
      font-family: monospace;
      font-size: 18px;
      font-weight: bold;
    }

    &:hover {
        background-color: rgba(150, 150, 150, 0.5);
    }
}


// .textColor {
//     color: $black;
// }

// .container.dark {
//   .textColor {
//     color: $white;
//   }
// }
