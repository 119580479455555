@import 'variables';

.userView {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 200px;
}

.lookupForm {
  padding: 20px 0;
  height: 100%;
  
  .row {
    padding: 5px 0;
    color: rgba(0, 0, 0, 0.3);

    &.active {
      color: inherit;
    }
  }
  .fieldSelector {
    font-size: 20px;
    padding: 9px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 1px 0;

    // hack to fix arrow padding
    border: 0;
    outline: 1px solid rgba(0, 0, 0, 0.3);
    border-right: 12px solid transparent;

    &:disabled {
      cursor: default;
    }
  }
  .field {
    display: inline-block;
    font-size: 20px;
    margin-left: 10px;
    padding: 10px;
    width: 400px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;

    &:invalid:not(:focus) {
      border: 1px solid red;
    }

    &:read-only {
      color: rgb(128, 128, 128);
    }
  }
  .buttons {
    display: inline-block;
  }
  button {
    padding: 7px 15px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
    margin-left: 10px;
    transition: opacity 0.2s, background-color 0.2s, color 0.2s;
  }
  button[disabled] {
    cursor: default;
  }
  .copyButton {
    margin-left: 10px;
  }
}

.dark .loading {
  background-color: rgba(10, 10, 10, 0.5);
}

.error {
  color: red;
  text-align: center;
  font-size: 28px;
  padding: 50px 0;
}

.data {
  font-size: 24px;
}

.avatar {
  height: 100px;
  width: 100px;
  font-size: 36px;
}