@import 'variables.scss';

.layoutErrorBoundary {
  height: 100vh;
}

.site {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-rows: min-content 1fr min-content;
  min-height: 100vh;

  header {
    grid-area: header;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 20px 0 10px;

    .logoImage {
      height: 40px;
      width: auto;
    }

    nav {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-template-rows: 40px;

      .userAvatar {
        width: 30px;
      }
    }
  }

  &.dark header {
    box-shadow: 0px 2px 4px -1px rgba(255, 255, 255, 0.4);
  }

  main {
    grid-area: main;
    max-width: 100vw;
  }

  footer {
    .footer {
      margin-top: 20px;
      margin-bottom: 20px;

      nav a, nav p, nav > label {
        // text-transform: uppercase;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        height: 20px;
      }

      hr {
        margin-top: 5px;
        margin-bottom: 20px;
        width: 100%;
      }

      .dropdown {
        font-family: inherit;
        font-size: inherit;
        background: none;
        color: inherit;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5px;
        border: none;
      }
    }
  }
}
